export const auth = state => state.auth;
export const currentUserType = state => state.auth.userTypeIds[0];
export const authErrors = state => state.auth.errors;

export const commonUserTypes = state => state.common.userTypes;

export const rolesData = state => state.roles.data.data;
export const totalRoles = state => state.roles.data.total;
export const fromRoles = state => state.roles.data.from;
export const toRoles = state => state.roles.data.to;
export const userPerPage = state => state.roles.userRowsPerPage;
export const currentPage = state => state.roles.data.current_page;
export const lastPage = state => state.roles.data.last_page;
export const searchPage = state => state.roles.searchValue;
export const filterPage = state => state.roles.filterValue;
export const sortingData = state => state.roles.sortingData;
export const updatedData = state => state.roles.isUpdatedData;

export const newRole = state => state.newRole;
export const newRoleSteps = state => state.newRole.steps;
export const newRoleName = state => state.newRole.name;
export const newRoleDescription = state => state.newRole.description;
export const newRoleUserType = state => state.newRole.user_type_id;
export const newRoleErrors = state => state.newRole.errors;

export const usersData = state => state.users.data.data;
export const totalUsers = state => state.users.data.total;
export const fromUsers = state => state.users.data.from;
export const toUsers = state => state.users.data.to;
export const currentPageUsers = state => state.users.data.current_page;
export const lastPageUsers = state => state.users.data.last_page;
export const userPerPageUsers = state => state.users.rowsPerPage;
export const usersFeature = state => state.newRole.actions.users;
export const rolesFeature = state => state.newRole.actions.roles;
export const sortingUsersData = state => state.users.sortingData;
export const searchPageUsers = state => state.users.searchValue;
export const updatedUsersData = state => state.users.isUpdatedData;

export const roleData = state => state.editRole;
export const editRoleErrors = state => state.editRole.errors;

export const rightPanel = state => state.rightPanel;

export const newUser = state => state.newUser;
export const newUserUserType = state => state.newUser.user_type_id;
export const newUserRole = state => state.newUser.role_name;
export const newUserErrors = state => state.newUser.errors;

export const editUser = state => state.editUser;
export const editUserErrors = state => state.editUser.errors;